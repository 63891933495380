import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  withRouter,
} from 'react-router-dom';
import qs from 'qs';

class AuthAPI {
  static login(username, password) {
    return axios({
      method: 'post',
      url: 'auth/login',
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
      },
      data: qs.stringify({
        username,
        password,
      }),
    }).then((res) => {
      if (res.status === 200) {
        sessionStorage.setItem('username', username);
        return { data: res.data, ret: true };
      }
      console.warn('not 200');
      return { data: res.data, ret: false };
    }).catch((err) => ({
      data: err.response.data.error,
      ret: false,
    }));
  }

  static logout() {
    return axios.get('auth/logout')
      .then((res) => {
        if (res.status === 200) {
          sessionStorage.removeItem('username');
          return true;
        }
        return false;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  static me() {
    return axios.get('me')
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
        return false;
      })
      .catch((err) => {
        console.log(err);
      });
  }
}

class LoginView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      message: '',
    };
  }

  handleLogin(e) {
    e.stopPropagation();
    e.preventDefault();
    const { username, password } = this.state;

    AuthAPI.login(username, password)
      .then(({ data, ret }) => {
        if (ret === true) {
          const { history, location } = this.props;
          const { from } = location.state || { from: { pathname: '/' } };
          history.push(from);
        } else {
          this.setState({ message: data });
        }
      });
  }

  render() {
    const { message } = this.state;
    return (
      <div style={{
        width: '100%',
        height: '500px',
      }}
      >
        <header className="login-form-header">
          <h1>Sign In</h1>
        </header>
        <form style={{ display: 'flex', justifyContent: 'center' }} onSubmit={this.handleLogin.bind(this)}>
          <div>
            <span style={{ color: 'red' }}>{message}</span>
          </div>
          <fieldset style={{ display: 'flex', width: '20%', height: '200px' }}>
            <div>
              <input
                id="username"
                type="text"
                placeholder="username"
                autoComplete="username"
                onChange={(e) => this.setState({ username: e.target.value })}
              />
            </div>
            <div>
              <input
                id="password"
                type="password"
                placeholder="password"
                autoComplete="current-password"
                onChange={(e) => this.setState({ password: e.target.value })}
              />
            </div>
            <div>
            <button type="submit">
              Login
            </button>
            </div>
          </fieldset>
        </form>
      </div>
    );
  }
}

LoginView.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  location: PropTypes.shape({ state: PropTypes.object }).isRequired,
};

export default withRouter(LoginView);
